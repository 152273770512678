import React, { useEffect, useState } from 'react';

const ModalCaptcha = ({ onDismissModal, onBookSelect, bookDetails }) => {
  const [errorMessage, setErrorMessage] = useState(''); // To store the error message
  const [captchaValid, setCaptchaValid] = useState(false); // To track captcha validity

  useEffect(() => {
    // Initialize Bootstrap modal using jQuery
    $('#modalCaptcha').modal({
      backdrop: 'static',
      keyboard: false,
    });

    // Show captcha when the modal is fully visible
    $('#modalCaptcha').on('shown.bs.modal', function () {
      getCaptcha('canvas'); // Generate captcha when modal opens
    });

    // Handle modal close event
    $('#modalCaptcha').on('hidden.bs.modal', function () {
      if (!captchaValid) {
        $('#modalCaptcha').modal('show'); // Keep the modal open if captcha is not valid
      } else {
        // Remove backdrop and call dismiss callback
        $('.modal-backdrop').remove(); // Remove the backdrop element
        onDismissModal(false); // Callback for modal close if captcha is valid
      }
    });

    // Cleanup function to remove event listeners
    return () => {
      $('#modalCaptcha').modal('hide');
      $('#modalCaptcha').off('shown.bs.modal'); // Remove the shown event listener
      $('#modalCaptcha').off('hidden.bs.modal'); // Remove the hidden event listener
    };
  }, [captchaValid]);

  
  const getCaptcha = (id) => {
    const canvas = document.getElementById(id);
    const pen = canvas.getContext("2d");
    const width = canvas.width, height = canvas.height;

    // Generate a 4-digit numeric CAPTCHA
    let captcha = Math.floor(1000 + Math.random() * 9000).toString();

    // Store in session storage
    sessionStorage.setItem("captcha", captcha);

    // Clear canvas with light gray background
    pen.fillStyle = "#D3D3D3"; // Light gray background
    pen.fillRect(0, 0, width, height);

    // Function to draw curved lines (some solid, some dotted)
    const drawCurvedLine = (dotted = false) => {
        pen.beginPath();
        if (dotted) {
            pen.setLineDash([5, 7]); // Dotted line pattern
        } else {
            pen.setLineDash([]); // Solid line
        }
        pen.moveTo(Math.random() * width, Math.random() * height);
        const cp1x = Math.random() * width, cp1y = Math.random() * height;
        const cp2x = Math.random() * width, cp2y = Math.random() * height;
        const endX = Math.random() * width, endY = Math.random() * height;
        pen.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, endX, endY);
        pen.strokeStyle = `rgba(50, 50, 50, ${Math.random() * 0.5 + 0.2})`;
        pen.lineWidth = Math.random() * 2 + 1;
        pen.stroke();
    };

    // Draw multiple random curved lines (some dotted, some not)
    for (let i = 0; i < 8; i++) {
        drawCurvedLine(i % 2 === 0); // Alternate between dotted and solid lines
    }

    // Draw CAPTCHA digits with a wave pattern
    let x = 20;
    const amplitude = 10; // Height of the wave
    const frequency = 2; // Controls the number of waves
    for (let i = 0; i < captcha.length; i++) {
        pen.font = `${Math.floor(Math.random() * 10 + 40)}px Arial bold`;
        pen.fillStyle = "#4D4D4D";
        pen.save();
        const yOffset = Math.sin(i * frequency) * amplitude; // Sin wave effect
        pen.translate(x, 40 + yOffset); // Move up/down in a wave pattern
        pen.rotate((Math.random() - 0.5) * 0.2); // Small random rotation
        pen.fillText(captcha[i], 0, 0);
        pen.restore();
        x += 35;
    }
};

  // Function to validate the captcha
  const validateCaptcha = () => {
    const enteredCaptcha = document.getElementById('txtCaptcha').value;
    const storedCaptcha = sessionStorage.getItem('captcha');

    // Check if the length of the entered captcha matches or exceeds the stored captcha
    if (enteredCaptcha.length === storedCaptcha.length) {
      if (enteredCaptcha === storedCaptcha) {
        $('#modalCaptcha').modal('hide'); // Close the modal if captcha is valid
        setErrorMessage(''); // Clear error message
        setCaptchaValid(true); // Set captcha as valid
        setTimeout(() => {
          onBookSelect(bookDetails, true)
        }, 350);
      } else {
        setErrorMessage('Invalid captcha. Please try again.'); // Set error message for wrong captcha
      }
    } else if (enteredCaptcha.length > storedCaptcha.length) {
      setErrorMessage('Input exceeds captcha length.'); // Error for exceeding length
    } else {
      setErrorMessage(''); // Clear error message if lengths don't match
    }
  };

  return (
    <div className="modal fade modal-responsive" id="modalCaptcha" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            {/* No close button to prevent closing */}
          </div>
          <div className="modal-body text-center">
            <div id="ModalCaptchaHolder">
              <div id="messageCaptchaModel"></div>
              <canvas width="220" height="60" style={{ border: '1px solid #dddddd' }} id="canvas"></canvas>
              <button
                type="button"
                onClick={() => getCaptcha('canvas')}
                className="btn btn-success margin-l15 margin-b55"
                style={{ borderRadius: '100%', fontSize: '18px' }}
              >
                <i className="fa fa-refresh"></i>
              </button>
              <div id="captchaForm" role="form">
                <div className="form-group">
                  <input
                    id="txtCaptcha"
                    name="txtCode"
                    type="text"
                    className="form-control"
                    placeholder="Enter Captcha"
                    onChange={validateCaptcha} // Validate captcha on input change
                  />
                </div>
                {/* Display error message if captcha is invalid */}
                {errorMessage && <div className="text-danger">{errorMessage}</div>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {/* No submit button needed */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCaptcha;
